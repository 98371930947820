<template>
  <div class="register-container">
    <el-form ref="form" label-width="0px" :model="form" :rules="rules">
      <!-- <el-input type="password" style="display: none" /> -->
      <el-form-item prop="userName">
        <el-input v-model="form.userName" placeholder="请输入手机号码" />
      </el-form-item>
      <!-- 验证码 -->
      <el-form-item class="yzm-box" prop="code">
        <!-- 请输入验证码 -->
        <div class="verififcation">
          <el-input type="text" v-model="form.code" placeholder="请输入验证码" />
          <!-- <el-input style="position: fixed; bottom: -9999px" /> -->
          <!-- <input type="text" placeholder="请输入验证码" v-model="form.code" /> -->
          <input
            v-show="!epmty"
            type="button"
            :disabled="!send"
            class="yzm"
            :value="codetext"
            @click="seedCode"
            v-bind="teSting"
          />
        </div>
      </el-form-item>

      <!-- 密码 -->
      <el-form-item prop="password">
        <!-- <el-input type="password" v-model="form.password" placeholder="请输入密码" /> -->
        <el-input
          type="text"
          onfocus="this.type='password'"
          v-model="form.password"
          placeholder="请输入密码"
        />
      </el-form-item>

      <!-- 请确认密码 -->
      <el-form-item prop="again">
        <el-input
          type="text"
          onfocus="this.type='password'"
          v-model="form.again"
          placeholder="请确认密码"
        />
      </el-form-item>
      <!-- 平台协议 -->
      <el-form-item class="xieyi-box" prop="radio">
        <!-- <el-radio v-model="radio" label="1">我已阅读并同意</el-radio> -->
        <el-radio-group v-model="form.radio">
          <el-radio label="1">我已阅读并同意</el-radio>
        </el-radio-group>
        <span class="xieyi-box-1" @click="dialogVisiblebtn($event)"
          >《平台用户协议》</span
        >
      </el-form-item>
      <el-form-item>
        <el-button
          :type="disabled ? 'disabled' : 'primary'"
          @click="submitForm"
          :disabled="disabled"
          >注册</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 协议弹框 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="70%"
      :append-to-body="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="dialog-title">平台用户协议</div>
      <div class="dialog-content">
        <p></p>
        <p>佰科在线培训平台手机帐号服务条款    </p>
        <p>
          欢迎申请使用佰科公司及其他合作运营主体（下列统称为“佰科公司”）提供的产品和服务。请您（下列统称为“用户”）仔细阅读、充分理解以下全部内容，其中限制或免除责任的条款，以及其他涉及用户重大权益的条款（如违约处罚、争议管辖等）将会以加粗或下划线标注等形式提示用户重点注意。
        </p>
        <p>一、服务条款的确认和接纳</p>
        <p>
          1.1本服务条款是用户与佰科公司之间关于其注册以及使用佰科公司提供的相关产品和服务所订立的协议。如用户选择以页面确认或其他任何方式明示或者默示表示接受本服务条款，或以下载、安装、登录等方式使用佰科公司提供的相关产品和服务，即表示用户与佰科公司已达成协议，自愿接受本服务条款的所有内容。如用户不同意本服务条款任意内容，请勿注册佰科手机帐号或使用佰科公司提供的相关产品和服务。
        </p>
        <p>
          1.2本服务条款的效力范围及于佰科公司提供的通过佰科手机帐号注册和使用的全部产品和服务（下列统称“佰科服务”），除佰科公司另行明确声明外，用户在使用任何佰科单项服务时，将受本服务条款的约束。当用户使用佰科各单项服务时，用户同意以单项服务要求的方式同意该单项服务的服务条款以及佰科公司在该单项服务中发出的各类公告、规则（下列统称为“单项条款”），在此情况下单项条款与本服务条款同时对用户产生效力。若单项条款与本服务条款存在同类条款的冲突，则在单项条款约束范围内应以单项条款为准。
        </p>
        <p>
          1.3佰科公司有权在必要时通过在网页上发出公告等合理方式修改本服务条款以及各单项条款。用户在享受佰科各单项服务时，应当及时查阅了解修改的内容，并自觉遵守本服务条款以及该单项服务的相关条款。用户如继续使用本服务条款涉及的产品和服务，则视为对修改内容的同意，当发生有关争议时，以最新的条款内容为准；用户在不同意修改内容的情况下，有权停止使用本服务条款涉及的产品和服务。
        </p>
        <p>二、佰科手机帐号服务简介</p>
        <p>
          2.1本服务条款所称的“佰科手机帐号”是指用户以手机号码作为帐号注册的合法、有效的帐号，佰科手机帐号注册成功后帐号即为手机号。
        </p>
        <p>
          2.2用户理解并确认，佰科手机帐号是佰科公司按照本服务条款授权注册用户使用佰科服务的数字标识。在法律法规允许的范围内，佰科手机帐号的所有权（手机号码本身除外），以及注册、使用佰科手机帐号相关的服务数据和记录，包括但不限于所有注册、登录、消费记录、产品日志、客服记录和相关的使用统计数据等，归佰科公司或具体产品运营主体所有。
            
        </p>
        <p>
            2.3用户完成佰科手机帐号申请注册程序后，佰科公司按照本服务条款授权用户使用网
          易服务，或者以佰科公司明确许可的其他目的，依照佰科公司的服务规则使用该帐号。佰科
          公司运用自己的操作系统通过国际互联网络为用户提供各项服务。用户必须：
        </p>

        <p>（1）提供设备，如个人电脑、手机或其他上网设备。</p>
        <p>（2）个人上网和支付与此服务有关的费用。</p>
        <p>三、佰科手机帐号注册规则</p>
        <p>
          3.1用户确认，在注册或使用佰科服务时，应当具备中华人民共和国法律规定的与其行为相适应的民事行为能力，确保有能力对所使用佰科服务的一切行为独立承担责任。若用户不具备前述主体资格，请在监护人的陪同下阅读本服务条款，并在取得监护人对本服务条款及对使用佰科服务的行为的同意之后，方可注册并使用佰科服务；佰科公司在依据法律规定或本服务条款要求用户承担责任时，有权向其监护人追偿。
        </p>
        <p>
          3.2用户还需确保其不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则应当停止注册或使用佰科服务，同时用户理解违反前述要求可能会造成其无法正常注册及使用佰科服务。
        </p>
        <p>
          3.3佰科手机帐号注册资料包括但不限于：用户的手机号码、密码及注册佰科手机帐号或更新佰科手机帐号注册资料时输入的所有信息或更新佰科手机帐号时输入的所有信息以及用户使用佰科各单项服务时输入的名称、头像等所有信息。用户在注册佰科手机帐号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等底线和准则，不得在佰科手机帐号注册资料中出现违法和不良信息，且用户保证其在注册和使用帐号时，不得有以下情形：
        </p>
        <p>（1）违反宪法或法律法规规定的；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的，损害公共利益的；</p>
        <p>
          （4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
        </p>
        <p>（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
        <p>（6）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（8）散布谣言，扰乱社会秩序，破坏社会稳定的</p>
        <p>（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（10）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（11）含有法律、行政法规禁止的其他内容的。</p>
        <p>
          若用户提供给佰科公司的帐号注册资料不准确，不真实，含有违法或不良信息的，佰科公司有权不予注册，并保留终止用户使用佰科手机帐号及佰科服务的权利。若用户以虚假信息骗取帐号注册或帐号头像、个人简介等注册资料存在违法和不良信息的，佰科公司有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册帐号名称的，佰科公司有权注销该帐号，并向政府主管部门进行报告。
        </p>
        <p>3.4 根据相关法律、法规规定以及考虑到佰科产品服务的重要性，用户同意：</p>
        <p>
          （1）在注册佰科手机帐号时提交个人有效身份信息进行实名认证（包括但不限于手机号码、身份证件号码等）；
        </p>
        <p>
          （2）提供必要、准确的帐户注册资料，并不断更新注册资料，以便佰科公司向用户提供帮助，或更好地为用户提供服务。
        </p>
        <p>
          若用户提交的信息不完整或不准确，则可能无法使用佰科服务或在使用过程中受到限制。佰科公司提醒用户，用户注册佰科手机帐号或更新注册信息时填写的证件号码，在注册佰科手机帐号成功或补充填写后将无法进行修改，请用户慎重填写各类注册信息。
        </p>
        <p>
          3.5 用户同意，其提供的真实、准确、合法的佰科手机帐号注册资料是作为认定用户与其佰科手机帐号的关联性以及用户身份的唯一证据。用户在享用佰科各项服务的同时，同意接受佰科提供的各类信息服务。发生争议时，用户同意以佰科公司或具体产品运营主体的系统数据为准，佰科公司保证该数据的真实性。但是如果单项条款存在与前述不同的约定，则以单项条款约定为准。
        </p>
        <p>四、服务的变更、中止、取消或终止</p>
        <p>
          4.1佰科公司始终在不断变更和改进服务。佰科公司可能会增加或删除功能，也可能暂停或彻底停止某项服务。用户同意佰科公司根据自身商业决策等原因可能会选择终止佰科手机帐号服务或其他佰科服务。如有此等情形发生，佰科公司会采取网站公告等合理形式通知用户。除法律有明确规定的情况外，佰科公司有权不经用户申请并直接为其办理余额退款（如有）。
        </p>
        <p>
          4.2经国家行政或司法机关的生效法律文书确认用户存在违法或侵权行为，或者佰科公司根据自身的判断，认为用户的行为涉嫌违反本服务条款或佰科公司不时公布的使用规则等内容，或涉嫌违反法律法规的规定的，则佰科公司有权中止、中断或终止向用户提供服务，并不承担任何责任。
        </p>
        <p>五、用户隐私制度</p>
        <p>
          尊重用户隐私是佰科公司的一项基本政策。佰科公司将按照佰科网站上公布的隐私政策（http://corp.163.com/gb/legal.html
          ）收集、存储、使用、披露和保护用户的个人信息。请用户完整阅读上述隐私政策，以帮助用户更好地保护自身个人信息。如果用户是未满14
          周岁的未成年人，请通知监护人共同阅读并确认佰科公司的《佰科儿童个人信息保护规则及监护人须知》（https://hc.reg.163.com/iTerm/doc.html?id=347），并在使用服务、提交个人信息之前，务必寻求监护人的同意和指导。
        </p>
        <p>六、帐号安全和保障</p>
        <p>
          6.1用户的帐号为其自行设置并由用户自行保管。用户应维持密码及佰科手机帐号的机密安全，如果用户未保管好自己的帐号和密码而对用户、佰科公司或第三方造成损害，用户将负全部责任。
        </p>
        <p>
          6.2佰科公司根据本服务条款对佰科手机帐号的使用授权，仅限于初始申请注册人。未经佰科公司明确许可，初始申请注册人不得赠与、借用、租用、转让或售卖佰科手机帐号或者以其他方式许可其他主体使用佰科手机帐号。否则，用户应当自行承担由此产生的任何责任和后果；同时，佰科公司保留在未经通知的情况下中止、中断或终止使用该帐号，并追究上述行为人法律责任的权利。
        </p>
        <p>
          6.3用户同意，若发现任何非法使用用户帐号或安全漏洞的情况，有义务立即通告佰科公司。佰科公司将协助用户冻结帐号、更改密码或进行其他安全设置。用户理解并同意，佰科公司对其请求采取行动需要合理的时间，对在采取行动前已经产生的以及由用户引发的后果（包括但不限于用户的任何损失）不承担任何责任。
        </p>
        <p>
          6.4为了保护用户帐号的安全，使用户可以更加安全地使用佰科服务，佰科公司可能会不断升级、完善我们的安全技术防护措施。若佰科公司根据自己的判断发现用户的帐号存在或可能存在被盗号等安全隐患时，为了保障用户的帐号安全，财产免受损失等，可能会采取向用户发出一定的提示、中止用户对帐号的使用，或者在用户登录或使用帐号时采取一定的措施，包括但不限于要求用户验证/更改/绑定手机号码、修改密码等，用户需配合佰科公司进行相应的帐号保护操作。用户理解并同意，如果拒绝佰科公司的帐号保护操作，或未能通过上述帐号保护操作的，佰科公司有权不予恢复该帐号的使用权，或限制该帐号的部分或全部功能。用户可以通过佰科帐号修复支持中心（https://mima.163.com/nie/），向佰科公司申请修复上述帐号。为了用户的帐号安全，用户应配合如实提供身份证明及相关资料，以便佰科公司进行核实。用户应充分理解其帐号修复申请并不必然被通过，佰科公司有权决定是否同意用户的帐号修复申请。
        </p>
        <p>七、用户行为规范</p>
        <p>
          7.1用户理解并同意，佰科公司一直致力于为用户提供文明健康、规范有序的网络环境，用户对佰科服务的使用必须遵守所有适用于服务以及用户所属和/或所处国家或地区的的地方法律、国家法律和国际法律，不得利用佰科服务从事违法违规、干扰佰科服务正常运营、以及侵犯其他用户或第三方合法权益的行为。
        </p>
        <p>7.2用户不得在佰科服务的页面上或利用佰科服务制作、复制、发布、传播以下信息：</p>
        <p>(1) 违反宪法确定的基本原则的；</p>
        <p>(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>(3) 损害国家荣誉和利益的；</p>
        <p>
          (4)
          歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
        </p>
        <p>(5) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
        <p>(6) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>(7) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>(8) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>(9) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
        <p>(10) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>(11) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
        <p>(12) 以非法民间组织名义活动的；</p>
        <p>
          (13)
          可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等损害未成年人身心健康的；
        </p>
        <p>(14) 散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息的；</p>
        <p>(15) 所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；</p>
        <p>(16) 含有法律、行政法规禁止或其他违反社会公序良俗的内容的。</p>
        <p>7.3用户不得利用佰科服务从事以下行为：</p>
        <p>
          (1)
          故意制作、传播计算机病毒等破坏性程序的，以及非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的行为；
        </p>
        <p>
          (2)向他人提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具，或明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助的行为；
        </p>
        <p>
          （3）设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的行为；
        </p>
        <p>
          （4）发布、传送、传播、储存侵害他人名誉权、肖像权、个人隐私、知识产权、商业秘密等合法权利的内容，以及从事侵犯他人合法权利的其他行为；
        </p>
        <p>（5）发表、传送、传播骚扰、广告信息及垃圾信息；</p>
        <p>（6）干扰或破坏佰科服务的正常运行，以及其他危害计算机信息网络安全的行为；</p>
        <p>
          （7）其他违反任何相关的法律、法规、规章、条例等其他具有法律效力的规范，以及佰科公司的所有其他规定和程序的行为。
        </p>
        <p>7.4除非法律允许或佰科公司书面许可，用户使用佰科服务的过程中不得从事下列行为：</p>
        <p>（1）删除佰科服务的应用程序及其副本上关于知识产权的信息；</p>
        <p>
          （2）对佰科服务的应用程序进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现佰科服务的应用程序的源代码；
        </p>
        <p>
          （3）对佰科公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
        </p>
        <p>（4）进入佰科服务的计算机信息网络、系统或者使用计算机信息网络资源的；</p>
        <p>
          （5）对佰科服务的应用程序和功能、运行中的指令、存储/处理或者传输的数据进行复制、删除、修改、增加、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非佰科公司经授权的第三方工具/服务接入佰科服务和相关系统；或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的的；
        </p>
        <p>
          （6）通过非佰科公司开发、授权的第三方软件、插件、外挂、系统，登录或使用佰科服务，或制作、发布、传播上述工具；
        </p>
        <p>
          （7）自行或者授权他人、第三方软件对佰科公司的应用程序及其组件、模块、数据进行干扰；
        </p>
        <p>（8）其他未经佰科公司明示授权的行为。</p>
        <p>
          7.5用户承诺，非经佰科公司同意，用户不能利用佰科各项服务进行销售或其他商业用途。如用户有需要将服务用于商业用途，应书面通知佰科公司并获得佰科公司的明确授权。
        </p>
        <p>八、责任承担和责任限制</p>
        <p>
          8.1用户须为自己佰科手机帐号下的一切行为承担法律责任，包括用户所发表的任何内容以及其它人使用用户的电脑、手机、帐号而产生的任何后果。如用户或使用用户帐号的其他人利用佰科公司的服务侵害他人民事权益的，用户应当承担侵权等法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在佰科公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予佰科公司等额的赔偿。
        </p>
        <p>
          8.2用户理解，如佰科公司发现或者收到被侵权人举报或投诉用户的行为存在违反相关法律法规、本服务条款规定情形的，佰科公司有权根据自己的判断不经通知随时采取限制、中止、冻结或终止用户对佰科服务的使用等措施，并依据法律法规保存有关信息向国家有关机关报告等；由此给用户带来的一切损失（包括但不限于通信中断，用户资料、邮件和虚拟财产及相关数据、增值服务、产品或服务等的清空或无法使用等），由用户自行承担且佰科公司不退还用户已支付但尚未使用完毕的费用。
        </p>
        <p>
          8.3用户理解，为保证佰科服务的安全性及产品功能的一致性，佰科公司将不定期对佰科服务进行更新。用户应将软件更新到最新版本，如用户未及时更新到最新版本，佰科公司不保证用户一定能正常使用佰科服务。
        </p>
        <p>
          8.4 佰科公司对佰科手机帐号上所有服务将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任。
        </p>
        <p>8.5除非由于佰科原因导致，否则佰科公司将不承担任何退还已收取费用的义务。</p>
        <p>
          8.6在法律法规允许的范围内，佰科公司不对与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失承担赔偿责任。
        </p>
        <p>九、不可抗力及其他免责事由</p>
        <p>
          用户理解并同意，鉴于互联网的特殊性，在使用佰科服务的过程中可能会遇到以下情况使服务发生中断，出现下述情况时，不视为佰科公司违约，若由此给用户造成的损失佰科公司将予以免责。
        </p>
        <p>
          （1）不可抗力，包括但不限于自然灾害、疫情、政府行为、法律法规颁布调整、战争动乱、通信线路中断、网络攻击、病毒入侵、网路故障、电信部门技术调整和政府管制等不能预见、不能克服并不能避免的客观情况；
        </p>
        <p>（2）佰科公司在进行机房维护、设备检修、系统升级或优化时，需要中断服务的；</p>
        <p>
          （3）由于用户自身或第三方的原因导致的服务中断、终止，包括用户操作不当、第三方产品或服务提供者的软硬件故障等。
        </p>
        <p>十、通知</p>
        <p>
          10.1所有发给用户的通知都可通过电子邮件、常规的信件或在网站显著位置公告等方式进行传送。佰科公司将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更、或其它重要事情。
        </p>
        <p>
          10.2为使用户及时、全面了解佰科公司提供的各项服务，用户同意佰科公司可以利用佰科服务多次、长期地发布商业性广告和商业性短信息，包括但不限于在佰科服务的登录页面及登录后任何页面内放置商业广告、向佰科服务的用户发送商业性广告邮件、商业性短信息以及在佰科服务的用户发出的电子邮件内附上商业性广告及／或广告链接等形式。
        </p>
        <p>十一、知识产权声明</p>
        <p>
          11.1佰科公司提供服务时涉及到的佰科产品的所有权以及相关软件的知识产权归佰科公司所有。
        </p>
        <p>
          （1）佰科公司提供的内容包括但不限于：非用户上传/提供的文字、软件、图片、音频、视频、图表、程序代码、界面设计、版面框架、数据资料、账号等，所有这些内容的知识产权及相关智力成果的权利均属于佰科公司，并受版权、商标、专利和其它相关法律的保护。未经佰科公司授权，用户不得使用上述内容，并且用户未经许可，不得对上述内容进行包括但不限于复制、销售、重制、再创作等行为。
        </p>
        <p>
          （2）佰科公司注册的佰科、NetEase等，以及佰科公司的其他标志及佰科产品、服务的名称，均为佰科公司之商标（以下简称“佰科标识”）。未经佰科公司事先书面同意，用户不得将佰科标识以及佰科公司享有相关权利的其他标识以不合法或不合理的任何方式使用或作其他处理，也不得向他人表明用户有权使用佰科标识。
        </p>
        <p>
          11.2佰科公司尊重知识产权并注重保护用户享有的各项权利。在佰科服务中，用户可能需要通过上传、发布等各种方式向佰科公司提供内容。在此情况下，用户仍然享有此等内容的完整知识产权。用户承诺在使用佰科提供的服务时发布上传的内容（包括但不限于文字、图片、音视频、软件代码等一切受知识产权保护的内容）均由用户原创或已获得合法授权。用户在提供内容时将授予佰科公司一项全球性的免费许可，允许佰科公司使用、传播、复制、修改、再许可、翻译、改编、创作衍生作品、出版、表演及展示此等内容。用户特别授权佰科公司以自己名义或委托第三方对侵权用户上传发布的享有知识产权内容的行为采取法律行动，包括但不限于投诉、诉讼等必要的维权措施。
        </p>
        <p>十二、法律适用及争议解决</p>
        <p>12.1本服务条款适用中华人民共和国的法律，并且排除一切冲突法规定的适用。</p>
        <p>
          12.2如使用本服务条款项下服务中出现纠纷的，用户与佰科公司应友好协商解决，若协商不成，用户同意将纠纷提交中国国际经济贸易仲裁委员会仲裁解决，并由3名仲裁员进行审理。仲裁裁决是终局的，对双方均有约束力。若单项条款与本服务条款在管辖约定内容上存在冲突，则在单项条款约束范围内应以单项条款为准。
        </p>
        <p>
          十三、青少年用户特别提示 青少年用户必须遵守全国青少年网络文明公约：
          要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
        </p>

        <p>十四、佰科手机帐号的有效期</p>
        <p>
          14.1用户清楚知悉佰科手机帐号存在有效期，并同意不定时登录使用佰科手机帐号以延续其有效期。
        </p>
        <p>
          14.2如果用户注册佰科手机帐号后连续180天没有登录，则佰科公司有权冻结该帐号。在冻结期内用户可以通过密保邮箱+手机验证码、原始密码+手机验证码、客服申诉等途径解除冻结，具体操作方式用户可在佰科公司网站合适位置查询或咨询客服了解。如被冻结的佰科手机帐号在冻结期内被其他用户申请重新注册，并通过了注册时必须的验证，佰科公司有权回收该帐号供其他用户注册使用。
        </p>
        <p>
          14.3当帐号被冻结后，原佰科手机帐号下的所有资料以及全部服务数据（包括但不限于佰科充值一卡通点数信息、游戏帐号信息等）佰科公司将为原用户保存540天（自该帐号最后一次登录之日起计算，也称“保存期”），如原用户连续540天没有通过客服申诉并提交佰科公司要求的身份认证信息材料的，则原佰科手机帐号下的佰科充值一卡通点数自该帐号最后一次登录之日起的第540天24时到期作废，佰科公司有权删除原佰科手机帐号下的所有资料以及全部服务数据。如用户在原帐号被回收后且在该帐号的保存期内，通过客服申诉并提交佰科公司要求的身份认证信息材料的，则该用户原佰科手机帐号下的佰科充值一卡通点数可以转移到该用户手机号关联的新佰科手机帐号。
        </p>
        <p>
          14.4成功登录佰科手机帐号是指通过佰科手机帐号密码认证或手机验证码认证，包括但不限于如下方式：
             
        </p>
        <p> (1) 通过游戏和手机应用等客户端软件登录；    </p>
        <p>
           (2) 通过web方式端登录；
          佰科充值一卡通是指：佰科公司发行的一种带有点数的一次性预付卡，供用户消费佰科公司所有可以用点数支付的收费产品，计算单位为“点”。
          佰科充值一卡通点数到期作废是指：佰科手机帐号下存在的佰科充值一卡通点数将不能被用户使用，该帐号下的佰科充值一卡通点数记录被清空，并且不可以恢复。佰科公司也不会对此做出任何的补偿或者赔偿。
        </p>

        <p>十五、其他</p>
        <p>
          15.1佰科公司不行使、未能及时行使或者未充分行使本服务条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响佰科公司在将来行使该权利。
        </p>
        <p>
          15.2如本服务条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务条款的其余条款仍应有效且具有约束力，佰科公司及用户均应尽力使该条款设定的本意得到实现。
        </p>
        <p>15.3本服务条款中的标题仅为方便而设，不作为解释本服务条款的依据。  </p>

        <p>
          如用户对本服务条款内容有任何疑问，可拨打客服电话（游戏用户请拨打：020- 8391
          8160，非游戏用户请拨打：020-
          83568094）或登录帮助中心（http://reg.163.com/help/help.shtml）进行查询。
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { register, verifyCode } from "@/api/login";
import { getToken } from "@/utils/auth";
import { Message } from "element-ui";
export default {
  name: "register",
  data() {
    let checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };

    let checkPassword = (rule, value, callback) => {
      if (
        !/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*/.]+$)(?![a-zA-z\d]+$)(?![a-zA-z!@#$%^&*/.]+$)(?![\d!@#$%^&*/.]+$)[a-zA-Z\d!@#$%^&*/.]+$/.test(
          value
        )
      ) {
        callback(new Error("密码必须包含字母数字和特殊字符"));
      } else {
        callback();
      }
    };
    let checkAgain = (rule, value, callback) => {
      if (value != this.form.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };

    return {
      // 禁用状态
      disabled: false,
      radio: "",
      dialogVisible: false,
      show: false,
      iphone: "",
      code: "",
      password: "",
      isregist: false,
      //输入空为空时
      sendregis: true,
      //必填的填写完毕
      regisin: false,
      codetext: "获取验证码",
      send: true,
      //验证码样式
      sendcode: true,
      codein: false,
      funikm: true,
      epmty: true,
      iphones: "",
      queryForm: {
        phone: "17370349207",
        key: "register",
      },
      form: {
        userName: undefined,
        password: undefined,
        code: undefined,
        again: undefined,
        verification: undefined,
        radio: undefined,
      },
      rules: {
        userName: [{ validator: checkUsername, trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "密码长度为6到18位", trigger: "blur" },
          // { validator: checkPassword, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        again: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: checkAgain, trigger: "blur" },
        ],
        radio: [
          { required: true, message: "请选择是否我已阅读并同意", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    //发送验证码
    seedCode() {
      var that = this;
      // console.log(this.queryForm.phone);
      //时间
      var countdown = 60;
      codetime();
      function codetime() {
        if (countdown == 0) {
          that.codetext = "获取验证码";
          countdown = 60;
          that.send = true;
          that.codein = false;
          that.sendcode = true;
          return;
        } else {
          countdown--;
          that.codetext = "" + countdown + "秒后重发";
          that.send = false;
          that.codein = true;
          that.sendcode = false;
        }
        setTimeout(function () {
          codetime();
        }, 1000);
      }
      //发送验证码
      verifyCode(this.queryForm).then((res) => {
        // console.log("发送验证码成功");
        // console.log(res);
        setTimeout(() => {
          this.disabled = false;
        }, 1000);
      });
      // getcode({
      //   mobile: that.iphone,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     //手机号码已经存在
      //     that.iphones = error.mobile[0];
      //     // that.showToast();
      //   });
    },
    //手机号码不正确的提示
    epmtyCode() {
      this.iphones = "手机号码不正确";
      // this.showToast();
    },
    //
    handleClose(done) {
      done();
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
    // 协议弹框
    dialogVisiblebtn: function () {
      this.dialogVisible = true;
    },
    //
    submitForm() {
      // console.log(this.$parent);
      // console.log(this.form.radio);
      // if(this.form.radio == undefined){
      //   this.$message({
      //     showClose: true,
      //     message: '请查看勾选平台用户协议',
      //     type: 'warning'
      //   });
      //   return false
      // }
      // this.$parent.flag = true;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // this.disabled = false;
          register(this.form).then((res) => {
            if(res.code == 200){
              Message({
                message: "注册成功",
                type: "success",
              });
              setTimeout(() => {
                window.history.back();
              }, 1000);
            }
            // console.log("哈哈", res.code);
          });
          if (localStorage.getItem("disable") === "500") {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
        } else {
          // console.log("error submit!!");
          // register(this.form).then((res) => {
          //   console.log("获取code", localStorage.getItem("disable"));
          // });
          if (localStorage.getItem("disable") === "500") {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
          // setTimeout(() => {
          //   this.disabled = true;
          // }, 1000);
          return false;
        }
      });
    },
  },
  //监听
  computed: {
    //验证手机号码是否正确
    teSting() {
      if (!/^1(3|4|5|7|8|9)\d{9}$/.test(this.form.userName)) {
        console.log("手机号码不正确");
        this.send = false;
        this.epmty = false;
      } else {
        console.log("手机号码正确");
        this.send = true;
        this.epmty = false;
        this.queryForm.phone = this.form.userName;
      }
    },
  },
  created() {
    if (getToken() != null) {
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<style scoped lang="scss">
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
  // display: none;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #cccccc;
}
.yzm-box {
  position: relative;
  .yzm {
    position: absolute;
    top: -14px;
    right: 0px;
    width: 119px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background: #1d70ea;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    border: 0;
    outline: 0;
  }
}
::v-deep .el-radio__input {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 50px;
}
.xieyi-box-1 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d70ea;
  margin-left: 0px;
  cursor: pointer;
}
.dialog-title {
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
}
.dialog-content {
  line-height: 26px;
}
.yzm-tel {
  position: absolute;
  top: -5rem;
  right: 0vw;
  width: 29vw;
  height: 8rem;
  line-height: 8rem;
  text-align: center;
  background: #1d70ea;
  border-radius: 6px;
  color: #fff;
  padding-left: 2vw;
  font-size: 2.2rem;
}
::v-deep .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  height: 500px;
  overflow-y: auto;
}
::v-deep button.el-button.el-button--disabled.is-disabled {
  opacity: 0.9;
}

::v-deep.el-button.is-disabled:hover {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #3e7eff;
  border-color: #3e7eff;
}
</style>
